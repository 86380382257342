import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/warehousesStock";
import { format } from "utils/formatDate";
import { Icon, Tooltip } from "@mui/material";

export default function data(
  handleEdit,
  handleDelete,
  handleDetail,
  setLoading = () => {},
  warehouseId
) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data } = await Service.paginate(
      page,
      perPage,
      sortStr,
      filterStr,
      warehouseId?.id
    );

    setPerPage(10);
    setTotalRows(data?.length);

    data &&
      setRows(
        data?.map((row) =>
          Object({
            id: row._id,
            codCautela: row.codCautela,
            action: row,
            responsavel: row.responsavel,
            usuario: row.usuario,
            usado: row.usado,
            dataDeRetirada:
              row.dataDeRetirada &&
              format(new Date(row.dataDeRetirada), "dd/MM/yyyy"),
          })
        )
      );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Editar">
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            onClick={() => handleEdit(row._id)}
            iconOnly
            // disabled={row.status !== "Em Estoque transitório" && row.status !== "Utilizado Parcialmente"}
          >
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Detalhes">
          <MDButton
            variant="gradient"
            color="success"
            fullWidth
            type="submit"
            onClick={() => handleDetail(row._id)}
            iconOnly
          >
            <Icon fontSize="small">visibility</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Remover">
          <MDButton
            variant="gradient"
            color="error"
            fullWidth
            type="submit"
            onClick={() => handleDelete(row)}
            iconOnly
            sx={{ display: row.usado ? "none" : "flex" }}
          >
            <Icon fontSize="small">delete_forever</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const updateRows = (rows) => {
    setRows(
      rows.map((row) =>
        Object({
          id: row._id,
          action: row,
          codCautela: row.codCautela,
          responsavel: row.responsavel,
          usuario: row.usuario,
          usado: row.usado,
          dataDeRetirada: format(row.dataDeRetirada),
        })
      )
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(
      `&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`
    );
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(
      `&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`
    );
  };

  useEffect(() => {
    warehouseId?.id !== "" && fetchData();
  }, [page, perPage, sortStr, filterStr, warehouseId]);

  return {
    columns: [
      { headerName: "Código da Cautela", field: "codCautela", flex: 1 },
      {
        headerName: "Responsável pelo material",
        field: "responsavel",
        flex: 1,
      },
      { headerName: "Criado Por", field: "usuario", flex: 1 },
      { headerName: "Data da retirada", field: "dataDeRetirada", flex: 1 },
      { headerName: "Ações", field: "action", flex: 1, renderCell: action },
    ],

    rows,
    fetchData,
    updateRows,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
    sortStr,
    filterStr,
  };
}
